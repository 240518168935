<template>
  <div class="image-uploader">
    <div class="section-title-row"
         v-if="!noTitle">
      <div class="title">{{title}}</div>
      <div class="line"></div>
    </div>
    <el-upload :disabled="disabled"
               :class="{'disabled-upload': disabledUpload || disabled}"
               action="/bengche/common/upload"
               :limit="limit"
               list-type="picture-card"
               :on-preview="handlePreviewAttachment"
               :on-remove="handleRemoveAttachment"
               :on-success="handleSuccess"
               :file-list="images"
               :headers="header">
      <i class="el-icon-plus"></i>
    </el-upload>
    <div class="date-row"
         v-if="showCreateDate">
      <div class="date-info"
           v-for="i in initialImages"
           :key="i.id">{{i.createTime}}</div>
    </div>
    <el-image-viewer style="z-index: 9999;" v-if="showViewer"
                     :on-close="closeViewer"
                     :url-list="srcList" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'image-uploader',
  components: {
    ElImageViewer,
  },
  data() {
    return {
      showViewer: false,
      imageCount: 0,
      header: {},
      initialImages: [],
      srcList: [],
    }
  },

  props: {
    title: String,
    images: Array,
    noTitle: {
      type: Boolean,
      default: false,
    },
    limit: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    showCreateDate: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    images(v) {
      if (v) {
        this.imageCount = v.length
        this.initialImages = JSON.parse(JSON.stringify(v))
      }
    },
  },

  computed: {
    disabledUpload() {
      return this.limit && this.imageCount === this.limit
    },
  },

  methods: {
    handleSuccess(res) {
      this.initialImages.push({
        id: Math.random(),
        url: res.datas,
        createTime: dayjs().format('YYYY-MM-DD'),
      })
      this.imageCount++
      this.$emit('add', res.datas)
    },

    handleRemoveAttachment(file) {
      this.imageCount--
      if (file.response) {
        this.initialImages.splice(
          this.initialImages.findIndex((i) => i.url === file.response.datas),
          1
        )
        this.$emit('remove', file.response.datas)
      } else {
        this.initialImages.splice(
          this.initialImages.findIndex((i) => i.url === file.url),
          1
        )
        this.$emit('remove', file.url.substring('/files'.length))
      }
    },

    handlePreviewAttachment(file) {
      this.srcList = []
      this.srcList.push(file.url)
      this.showViewer = true
    },

    closeViewer() {
      this.showViewer = false
    },
  },
  mounted() {
    this.header.token = this.$cookie.get('token')
  },
}
</script>

<style lang="scss" scoped>
.date-row {
  display: flex;
  .date-info {
    text-align: center;
    width: 148px;
    margin-right: 8px;
  }
}
</style>
